import React from "react";

function Imprint() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-avda-blue">
      <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-lg">
        <h1 className="text-4xl font-bold text-avda-blue mb-4">Impressum</h1>
        <div className="mt-6 text-left text-gray-700">
          <p>
            <strong>Angaben gemäß § 5 TMG:</strong>
          </p>
          <p>Dardan Avdimetaj</p>
          <p>AVDA BAU</p>
          <p>Goethestraße 5</p>
          <p>89160 Dornstadt</p>

          <p className="mt-4">
            <strong>Kontakt:</strong>
          </p>
          <p>Telefon: +49 152 33680293</p>
          <p>E-Mail: info@avdabau.com</p>

          <p className="mt-4">
            <strong>
              Umsatzsteuer-Identifikationsnummer gemäß § 27 a
              Umsatzsteuergesetz:
            </strong>
          </p>
          <p>Keine USt-IdNr. vorhanden.</p>

          <p className="mt-4">
            <strong>Berufsbezeichnung:</strong> Bauunternehmen
            (Einzelunternehmen)
          </p>

          <p className="mt-4">
            <strong>Hinweis zur Eintragung:</strong>
          </p>
          <p>Nicht im Handelsregister eingetragen</p>

          <p className="mt-4">
            <strong>
              Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            </strong>
          </p>
          <p>Dardan Avdimetaj</p>
          <p>Goethestraße 5</p>
          <p>89160 Dornstadt</p>
        </div>
        <div className="mt-8">
          <p className="text-sm text-gray-500">
            © {new Date().getFullYear()} AVDA BAU. Alle Rechte vorbehalten.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Imprint;
