import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import Imprint from "./Imprint";

function Home() {
  return (
    <div className="flex flex-col items-center justify-center h-screen bg-avda-blue">
      <Helmet>
        <title>AVDA BAU - Webseite wird neu entwickelt</title>
        <meta
          name="description"
          content="Die AVDA BAU Webseite wird derzeit neu entwickelt. Kontaktieren Sie uns per E-Mail oder Telefon für weitere Informationen."
        />
        <meta
          name="keywords"
          content="AVDA BAU, Bauunternehmen, Ulm, Nähe Ulm, Ulm Umgebung, Kontakt, Bauprojekte"
        />
        <meta name="author" content="AVDA BAU" />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <div className="text-center p-8 bg-white rounded-lg shadow-lg max-w-lg">
        <h1 className="text-4xl font-bold text-avda-blue mb-4">
          AVDA BAU - Wir sind bald zurück!
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Unsere Webseite wird derzeit neu entwickelt. Bitte besuchen Sie uns
          bald wieder!
        </p>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">
            Kontaktinformationen
          </h2>
          <p className="text-gray-700 flex items-center justify-center mb-2">
            <FontAwesomeIcon
              icon={faEnvelope}
              className="mr-2 text-avda-orange"
            />
            <a
              href="mailto:info@avdabau.com"
              className="text-black hover:underline"
            >
              info@avdabau.com
            </a>
          </p>
          <p className="text-gray-700 flex items-center justify-center">
            <FontAwesomeIcon icon={faPhone} className="mr-2 text-avda-orange" />
            <a href="tel:+4915233680293" className="text-black hover:underline">
              +49 152 33680293
            </a>
          </p>
        </div>

        <div className="mt-8">
          <a href="mailto:info@avdabau.com?subject=Anfrage an AVDA BAU&body=Sehr geehrtes AVDA BAU-Team,">
            <button className="px-6 py-2 bg-avda-orange text-white rounded-full hover:bg-orange-600 transition duration-300">
              Kontaktieren Sie uns
            </button>
          </a>
        </div>

        <div className="mt-8">
          <p className="text-sm text-gray-500">
            © {new Date().getFullYear()} AVDA BAU. Alle Rechte vorbehalten.
          </p>
        </div>

        {/* Link zum Impressum */}
        <div className="mt-8">
          <Link
            to="/impressum"
            className="text-sm text-avda-orange hover:underline"
          >
            Impressum
          </Link>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<Imprint />} />
      </Routes>
    </Router>
  );
}

export default App;
